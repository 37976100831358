import React from "react"
import styled from "styled-components"

import Layout from "src/components/common/Layout"
import ContactHeroSection from "src/components/page/contact/ContactHeroSection"
import GetYourAdSection from "src/components/page/contact/GetYourAdSection"
import ChannelsSection from "src/components/common/ChannelsSection"
import PutYourShowSection from "src/components/page/contact/PutYourShow"
import AddOurChannelSection from "src/components/page/contact/AddOurChannelSection"

const ContactPage = () => {
  return (
    <Layout title="Contact">
      <ContactHeroSection />
      <GetYourAdSection />
      <PutYourShowSection />
      <AddOurChannelSection />
      <ChannelsSection />
    </Layout>
  )
}

export default ContactPage
