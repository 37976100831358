import React from "react"
import styled from "styled-components"

const CTAButton = ({ link, children }) => {
  return <StyledCTAButton href={link}>{children}</StyledCTAButton>
}

export default CTAButton

const StyledCTAButton = styled.a`
  display: inline-block;
  position: relative;
  width: fit-content;
  border: 2px solid #fff;
  border-radius: 156.737px;
  padding: 10px 30px;
  font-size: 25px;
  line-height: 39px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
  :hover {
    background: #fff;
    color: #000;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 32px;
  }
`
