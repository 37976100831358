import React from "react"
import CTAButton from "src/components/common/CTAButton"
import { StyledSplitSection } from "src/components/common/StyledSplitSection"
import styled from "styled-components"

const AddOurChannelSection = () => {
  return (
    <StyledAddOurChannelSection>
      <div className="content-side">
        <div className="content">
          <h2>
            Add Our Channel <br />
            to Your Platform
          </h2>
          <p>
            Energize your platform’s channel lineup with our unique mix of
            sports, entertainment and original programming.
          </p>
          <CTAButton link="mailto:bizdev@fubo.tv">Contact Us</CTAButton>
        </div>
      </div>
      <div className="image-side">
        <div className="image-container">
          <div className="image">
            <img
              src="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/contact/add-our-channel-v2.jpg?ch=width&auto=format,compress"
              alt=""
            />
          </div>
        </div>
      </div>
    </StyledAddOurChannelSection>
  )
}

export default AddOurChannelSection

const StyledAddOurChannelSection = styled(StyledSplitSection)`
  background: radial-gradient(
      100.24% 375.13% at 100.44% 40.72%,
      rgba(255, 136, 104, 0.2) 7.52%,
      rgba(0, 0, 0, 0) 100%
    ),
    #27001f;
  @media (max-width: 767px) {
    flex-direction: column;
    .image-side {
      align-self: flex-end;
    }
  }
  .content-side {
  }
  .image-side {
    .image-container {
      border: 1px solid rgba(255, 108, 159, 1);
      border-right: none;
      border-radius: 300px 0 0 300px;
      padding: 10px 0 10px 10px;
      .image {
        border-radius: 300px 0 0 300px;
      }
    }
  }
`
