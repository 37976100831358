import React from "react"
import CTAButton from "src/components/common/CTAButton"
import { StyledSplitSection } from "src/components/common/StyledSplitSection"
import styled from "styled-components"

const PutYourShowSection = () => {
  return (
    <StyledPutYourShowSection>
      <div className="image-side">
        <div className="image-container">
          <div className="image">
            <img
              src="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/contact/on-our-channel.jpg?ch=width&auto=format,compress"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="content-side">
        <div className="content">
          <h2>
            Put Your Show <br />
            on Our Channel
          </h2>
          <p>
            Fubo Sports viewers are hungry for sports content like yours.
            Contact us about adding your show.
          </p>
          <CTAButton link="mailto:ibonebrake@fubo.tv">
            Contact Issy Bonebrake
          </CTAButton>
        </div>
      </div>
    </StyledPutYourShowSection>
  )
}

export default PutYourShowSection

const StyledPutYourShowSection = styled(StyledSplitSection)`
  background: radial-gradient(
      100.24% 375.13% at -0.24% 77.07%,
      rgba(249, 254, 255, 0.2) 7.52%,
      rgba(0, 0, 0, 0) 100%
    ),
    #130e58;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    .image-side {
      align-self: flex-start;
    }
  }
  .image-side {
    .image-container {
      border: 1px solid rgba(201, 132, 255, 1);
      border-left: none;
      border-radius: 0 300px 300px 0;
      padding: 10px 10px 10px 0;
      .image {
        border-radius: 0 300px 300px 0;
      }
    }
  }
`
