import React from "react"
import styled from "styled-components"

export const StyledSplitSection = styled.div`
  display: flex;
  min-height: 500px;
  padding: 30px 0;
  box-sizing: border-box;
  .content-side {
    flex: 2 1 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      color: #fff;
      h2 {
        max-width: 400px;
        font-size: 45px;
        line-height: 52px;
        margin-bottom: 20px;
        @media (max-width: 767px) {
          font-size: 35px;
          line-height: 42px;
        }
      }
      p {
        max-width: 500px;
        font-size: 25px;
        line-height: 39px;
        font-weight: 400;
        margin-bottom: 30px;
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
  .image-side {
    flex: 1 2 auto;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .image-container {
      border: 1px solid rgba(255, 108, 159, 1);
      border-radius: 300px;
      overflow: hidden;
      padding: 10px;
      .image {
        border-radius: 300px;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
`
