import React from "react"
import styled from "styled-components"

const DownArrow = () => (
  <svg
    width="31"
    height="18"
    viewBox="0 0 31 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3961 16.6664C14.2992 17.5695 15.7633 17.5695 16.6664 16.6664L29.3852 3.94768C30.2883 3.0446 30.2883 1.5804 29.3852 0.677316C28.4821 -0.225772 27.0179 -0.225772 26.1148 0.677316L15.0313 11.7609L3.94768 0.677316C3.0446 -0.225772 1.5804 -0.225772 0.677316 0.677316C-0.225772 1.5804 -0.225772 3.0446 0.677316 3.94768L13.3961 16.6664Z"
      fill="url(#paint0_linear_504_1067)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_504_1067"
        x1="14.7517"
        y1="9.18344"
        x2="14.7517"
        y2="17.3808"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF3A03" />
        <stop offset="1" stop-color="#E3001B" />
      </linearGradient>
    </defs>
  </svg>
)

const ContactHeroSection = () => {
  return (
    <StyledContactHeroSection>
      <div className="video-bg">
        <video
          autoPlay
          playsInline
          muted
          loop
          poster="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/contact/fsn-contact-bg-video.jpg?ch=width&auto=format,compress"
        >
          <source src="https://assets.fubo.tv/marketing/lp/images/fsn/contact/fsn-contact-bg-video.mp4" />
        </video>
      </div>
      <div className="overlay"></div>
      <div className="content">
        <h1>Custom opportunities for your brand</h1>
        <p>
          Fubo owns exclusive streaming rights to several high profile live
          sports leagues, events, and shows offering plenty of opportunities for
          partner sponsorships and integrations.
        </p>
        <DownArrow />
      </div>
    </StyledContactHeroSection>
  )
}

export default ContactHeroSection

const StyledContactHeroSection = styled.div`
  position: relative;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 55px;

  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.9) 22.27%,
        rgba(0, 0, 0, 0) 81.53%
      );
    @media (max-width: 768px) {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        linear-gradient(
          360deg,
          rgba(0, 0, 0, 0.9) 22.27%,
          rgba(0, 0, 0, 0) 100%
        );
    }
  }
  .content {
    position: relative;
    z-index: 1;
    color: #fff;
    max-width: 1000px;
    text-align: center;
    h1 {
      font-size: 2.6rem;
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1.4rem;
      line-height: 1.5;
      font-weight: 500;
      margin-bottom: 36px;
      @media (max-width: 767px) {
        font-size: 1.2rem;
      }
    }
  }
`
